import { flog, sha256, toBase64Url } from '@heimdall/client';
import { from } from 'rxjs';

export const codeVerifierAndChallenge$ = from(
  createVerifierAndChallenge()
).pipe(flog('Code verifier and challenge'));

export async function createVerifierAndChallenge() {
  const codeVerifier = createCodeVerifier();
  const codeChallenge = await createCodeChallenge(codeVerifier);
  return { codeChallenge, codeVerifier };
}

async function createCodeChallenge(v: string) {
  const hashed = await sha256(v);
  const base64encoded = toBase64Url(hashed);
  return base64encoded;
}

function createCodeVerifier() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

function dec2hex(dec: number) {
  return ('0' + dec.toString(16)).slice(-2);
}

type Fin = {
  Platform: {
    isOpenFinEnvironment?: () => boolean;
  };
};

export function guessContext(): 'WEB' | 'OF' {
  const isOpenFin = window.fin?.Platform?.isOpenFinEnvironment?.() ?? false;
  return isOpenFin ? 'OF' : 'WEB';
}

declare global {
  interface Window {
    fin?: Fin;
  }
}
